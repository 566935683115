<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">List PPB KL</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-6">
                        <!--begin::Content-->
                        <div>
                          <div style="overflow-x:auto; overflow-y: hidden;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th>No</th>
                                          <th>Kode PTSP</th>
                                          <th style="width:350px;">Nama KL</th>
                                          <th>Nama Ketua</th>
                                          <th>Alamat</th>
                                          <th>Email</th>
                                          <th>Status</th>
                                          <th>Lihat Profil</th>
                                          <th>Kirim Akun</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in profil" :key="row.id">
                                          <td></td>
                                          <td>{{row.idkl}}</td>
                                          <td>{{row.nama_kl}}</td>
                                          <td>{{row.ketua}}</td>
                                          <td>{{row.alamat}}</td>
                                          <td>{{row.email}}</td>
                                          <td>{{row.status_profil}}</td>
                                          <td>
                                              <b-button @click="urlprofil(row.idkl)" size="sm" variant="success"><i class="flaticon-eye"></i></b-button>
                                          </td>
                                          <td>
                                              <b-button @click="kirimakun(row.idkl)" size="sm" style="background-color: #030A8C; border-color: #030A8C" variant="primary"><i class="flaticon-paper-plane"></i>Kirim</b-button>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
            </div>

        </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';

export default {
  data() {
    return {
      url: localStorage.getItem('baseapi'),
      token: localStorage.getItem('id_token'),
      headers: [
            {
            text: 'Kode PTSP',
            align: 'left',
            sortable: false,
            value: 'idkl',
            },
            { text: 'Nama Provinsi', value: 'nama_kl' },
            { text: 'Nama Ketua', value: 'ketua' },
            { text: 'Alamat', value: 'alamat' },
            { text: 'Email', value: 'email' },
            { text: 'No Hp', value: 'telepon' },
            { text: 'Lihat Profil', value: '' },
            { text: 'Kirim Akun', value: '' },
        ],
        search:'',
        profil:[],
        role:'ppb_kl'
    };
  },
  components: {
    KTCodePreview
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            order: [[0, 'desc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        } ).draw();
        }, 300)
    },
    loaddata(){
            axios.get(this.url+"/profil_ppb_kl", {
              headers: {
                  'xth': this.token
              }
            })
            .then(response => {
                this.profil = response.data.data;
                this.initDatatable()
            }).catch(error=>{
                return error
                });

            
    },
    urlprofil(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/list-peserta/profilppbkldetail/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    lihat(id){
        // localStorage.setItem('idprofil', id)
        // this.$router.push({ name: "Profil-PTSP" })
    },
    kirimakun(idkl){
      const id = idkl;
      axios
        .post(
          this.url+"/mailing",
          {
            id,
          }
          ,
          {
            headers: {
                "Content-type": "application/json",
                "xth": this.token,
                }
            }
            )
        .then((res) => {
            this.$bvToast.toast('Berhasil Dikirim', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            // this.loaddata()
            this.getdata()
            // localStorage.setItem('idptsp', JSON.stringify(res.data.data.idptsp))
          return res;
        })
        .catch((err) => {
          console.log(err)
          this.$bvToast.toast('Gagal Dikirim', {
          title: `Failed `,
          variant: `danger`,
          solid: true
        })
          return err;
        });
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  mounted() {
    this.getdata()
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "List PPB KL" }
    ]);
  }
};
</script>
